function mobileMenu() {
  const mobileMenuIcon = document.querySelector(".js-mobile-menu-icon");
  const mobileMenuCloseIcon = document.querySelector(
    ".js-mobile-menu-close-icon",
  );
  const mobileMenu = document.querySelector(".js-mobile-menu-container");
  const mobileMenuOverlay = document.querySelector(".js-mobile-menu-overlay");
  const mobileClosingElements = [mobileMenuCloseIcon, mobileMenuOverlay];

  if (!mobileMenuIcon) {
    return;
  }

  mobileMenuIcon.addEventListener("click", () => {
    mobileMenu.classList.add("open");
    mobileMenuOverlay.classList.add("open");
  });

  mobileClosingElements.forEach((el) => {
    el.addEventListener("click", function () {
      if (!mobileMenu.classList.contains("open")) {
        return;
      }
      mobileMenu.classList.remove("open");
      mobileMenuOverlay.classList.remove("open");
    });
  });
}

mobileMenu();
